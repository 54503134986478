import React, { useEffect } from 'react'
import { HAS_WINDOW } from '@plurix/ecom-pages/src/constants'
import SpinnerIcon from '@plurix/ecom-pages/src/assets/SpinnerIcon'
import { secureSubdomain } from '../../store.config'
import type { PageProps } from 'gatsby'
import '../styles/pages/club.scss'

const Club = ({ location: { search } }: PageProps) => {
  useEffect(() => {
    if (HAS_WINDOW) {
      // gatsby navigate doesn't work well with search params
      window.location.replace(`${secureSubdomain}/register${search}`)
    }
  }, [])

  return (
    <div className="grid-content page__section">
      <div className="spinner-container">
        <SpinnerIcon />
      </div>
    </div>
  )
}

export default Club
